import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Button,
  Box,
  colors,
  Container,
  Grid,
  Typography,
  makeStyles,
  Divider,
  Card,
  CardContent
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import Bounce from 'react-reveal/Bounce';

interface SubsciptionProps {
  className?: string;
  subscriptionRef: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: 10
  },
  box: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    border: 1,
    borderRadius: 7,
    padding: 20,
    paddingBottom: 30,
    minHeight: 150,
  },
  middleBox: {
    display: "flex",
    flexDirection: "column", 
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    border: 1,
    borderRadius: 7,
    padding: 20,
    paddingBottom: 30,
    minHeight: 150,
  },
  image: {
    width: 900,
    alignSelf: 'center',
    '& > img': {
      maxWidth: '50%',
      height: 'auto',
    },
    [theme.breakpoints.down('md')]: { width: 600 },
    [theme.breakpoints.down('sm')]: { width: 323 },
  },
  button1: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white
  },
  button2: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  }
}));

const Subsciption: FC<SubsciptionProps> = ({ className, subscriptionRef, ...rest }) => {
  const classes = useStyles();
  
  return (
    <div
      ref={subscriptionRef}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <Typography
          variant="h1"
          align="center"
          color="secondary"
          style={{ fontWeight: 'bold'}}
        >
          The earlier you start, the more you save.
        </Typography>
        <Box mt={8} alignItems={'center'} justifyItems={'center'}>
  
            <Card style={{ width: '100%', marginRight: 20}}>
               <CardContent>
                 <Typography variant="h4">
                  {`📧 Need help finding the perfect subscription plan for you or your organization? Reach out to us at `}<span><a href="mailto:libertefinancial@gmail.com">libertefinancial@gmail.com</a></span> {`! Our team is ready to assist you in discovering the ideal solution tailored to your needs. Drop us an email today to get started! 🌟`}
                 </Typography>
               </CardContent>
           </Card>
       
            {/* <Grid
              item
              xs={12}
              md={4}
            >
              <Bounce>
                <Box className={classes.box}>
                  <div style={{ marginTop: -37, backgroundColor: '#febf23', padding: 5, borderStyle: 'solid', top: -20, borderRadius: 1, left: 0, marginBottom: 10 }}>
                    <Typography
                      variant="caption"
                      gutterBottom
                      style={{ color: colors.common.black, fontWeight: 'bold', marginRight: 10, marginLeft: 10 }}
                    >
                      Best Value
                    </Typography>
                  </div>
                    <Typography
                      variant="h3"
                      gutterBottom
                      style={{ color: colors.common.white, fontWeight: 'bold', marginTop: 10 }}
                    >
                      Yearly Plan
                    </Typography>
                    <Typography
                      align="left"
                      variant="h1"
                      style={{ color: colors.common.white, marginTop: 20, fontWeight: 'bold', fontSize: 50 }}
                    >
                      $720
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ color: colors.common.white, marginTop: 20, marginBottom: 20 }}
                    >
                      save $120 per year
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ color: colors.common.white, marginTop: 20, marginBottom: 10 }}
                    >
                      Valid for one year
                    </Typography>
                    <Button 
                      component={RouterLink}
                      to="/register"
                      className={classes.button1} 
                      variant='contained' 
                      color='secondary' 
                      size='large' 
                      style={{ marginTop: 20 }}
                    >
                      Start Free Trial
                    </Button>
                </Box>
              </Bounce>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Bounce>
                <Box className={classes.middleBox}>
                    <Typography
                      variant="h3"
                      gutterBottom
                      style={{ color: '#4479A5', fontWeight: 'bold',  marginTop: 15  }}
                    >
                      6-Month Plan
                    </Typography>
                    <Typography
                      align="left"
                      variant="h1"
                      style={{ color: '#4479A5', marginTop: 20, fontWeight: 'bold', fontSize: 50 }}
                    >
                      $390
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ color: '#4479A5', marginTop: 20, marginBottom: 20 }}
                    >
                      save $60 per year
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ color: '#4479A5', marginTop: 20, marginBottom: 10 }}
                    >
                      Valid for 6 months
                    </Typography>
                    <Button 
                      component={RouterLink}
                      to="/register"
                      className={classes.button2} variant='contained' color='secondary' size='large' style={{ marginTop: 20 }}>
                      Start Free Trial
                    </Button>
                </Box>
              </Bounce>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Bounce>
                <Box className={classes.middleBox}>
                    <Typography
                      variant="h3"
                      gutterBottom
                      style={{ color: '#4479A5', fontWeight: 'bold' ,  marginTop: 15 }}
                    >
                      Monthly Plan
                    </Typography>
                    <Typography
                      align="left"
                      variant="h1"
                      style={{ color: '#4479A5', marginTop: 20, fontWeight: 'bold', fontSize: 50 }}
                    >
                      $70
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      color="primary"
                    >
                      Every month
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ color: '#4479A5', marginTop: 20, marginBottom: 10 }}
                    >
                      $840 per year
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ color: '#4479A5', marginTop: 10, marginBottom: 10 }}
                    >
                      14 days free trial
                    </Typography>
                    <Button 
                      component={RouterLink}
                      to="/register"
                      className={classes.button2} 
                      variant='contained' color='secondary' size='large' style={{ marginTop: 20 }}>
                      Start Free Trial
                    </Button>
                </Box>
              </Bounce>
            </Grid> */}
  
        </Box>
        <Box style={{ marginTop: 50 }}>
          <Typography
            component="p"
            color="textSecondary"
            align="center"
            style={{ fontFamily: 'Poppins', marginBottom: 30 }}
          >
            Start enjoying the Liberté you deserve.
          </Typography>
        </Box>
        <Box justifyContent={'center'} display={'flex'}>
            <img
              className={clsx(classes.image)}
              src="/static/home/fam.png"
            />
          </Box>
      </Container>
    </div>
  );
};

Subsciption.propTypes = {
  className: PropTypes.string
};

export default Subsciption;
