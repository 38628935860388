/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
// import { useTour } from '@reactour/tour';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import {
  Map as MapIcon,
  // Calendar as CalendarIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Mail as MailIcon,
  Briefcase as BriefcaseIcon
} from 'react-feather';
import ReportIcon from '@material-ui/icons/Assignment';
import ChartIcon from '@material-ui/icons/InsertChart';
import AccountIcon from '@material-ui/icons/AccountBalance';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CalendarIcon from '@material-ui/icons/InsertInvitation';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EmailIcon from '@material-ui/icons/Email';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import PaymentsIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { User, UserRole } from '../../../types/user';
import RadialChart from '../../../components/chart/RadialChart';
import useSettings from '../../../hooks/useSettings';
import { useUpdateUserSetting } from '../../../views/account/account.hooks';
import { getLastSubscription, getSubscriptionDetails } from '../../../common/utils';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  roles?: string[];
}

interface Section {
  items: Item[];
  roles?: string[];
  subheader?: string;
}

const sections: Section[] = [
  {
    // subheader: 'Reports',
    roles: ['Client'],
    items: [
      {
        title: 'Dashboard',
        icon: ChartIcon,
        href: '/portal/clients/dashboard',
        roles: ['Client'],
      }
    ]
  },
  {
    // subheader: 'Reports',
    roles: ['SuperAdmin'],
    items: [
      {
        title: 'Dashboard',
        icon: ChartIcon,
        href: '/portal/admins/dashboard',
        roles: ['SuperAdmin'],
      }
    ]
  },
  {
    // subheader: 'Management',
    roles: ['SuperAdmin', 'Admin', 'Client'],
    items: [
      {
        title: 'Admins',
        icon: SupervisorAccountIcon,
        href: '/portal/admins',
        roles: ['SuperAdmin']
      },
      {
        title: 'Clients',
        icon: PeopleIcon,
        href: '/portal/clients',
        roles: ['SuperAdmin', 'Admin']
      },
      {
        title: 'Financial',
        icon: AccountIcon,
        href: '/portal/management/financial',
        roles: ['Client'],
        items: [
          {
            title: 'Financial Information',
            href: '/portal/management/financial/information',
            roles: ['Client'],
          },
          // {
          //   title: 'Central Account',
          //   href: '/portal/management/financial/central-account',
          //   roles: ['Client'],
          // },
          // {
          //   title: 'Bank Account',
          //   href: '/portal/management/financial/bank-account',
          //   roles: ['Client'],
          // },
          // {
          //   title: 'Income',
          //   href: '/portal/management/financial/income',
          //   roles: ['Client'],
          // },
          // {
          //   title: 'Consumer Debts',
          //   href: '/portal/management/financial/consumer-debts',
          //   roles: ['Client'],
          // },
          // {
          //   title: 'Household Expenses',
          //   href: '/portal/management/financial/household-expenses',
          //   roles: ['Client'],
          // },
          // {
          //   title: 'Mortgages',
          //   href: '/portal/management/financial/mortgages',
          //   roles: ['Client'],
          // }
        ]
      },
      {
        title: 'Reports',
        icon: ReportIcon,
        href: '/portal/reports',
        roles: ['Client'],
        items: [
          {
            title: 'Transactions',
            href: '/portal/reports/transaction',
            roles: ['Client'],
          },
          {
            title: 'Graphs',
            href: '/portal/reports/graphs',
            roles: ['Client'],
          },
        ]
      },
      // {
      //   title: 'Calendar',
      //   href: '/portal/calendar',
      //   icon: CalendarIcon,
      //   roles: ['Client']
      // },
    ],
  },
  {
    roles: ['SuperAdmin'],
    items: [
      {
        title: 'Invites',
        icon: EmailIcon,
        href: '/portal/invites',
        roles: ['SuperAdmin'],
      }
    ]
  },
  {
    roles: ['SuperAdmin'],
    items: [
      {
        title: 'Subscription Types',
        icon: SubscriptionsIcon,
        href: '/portal/subscription',
        roles: ['SuperAdmin'],
      }
    ]
  },
  {
    roles: ['SuperAdmin'],
    items: [
      {
        title: 'Stripe',
        icon: LocalAtmIcon,
        href: '/portal/stripe',
        roles: ['SuperAdmin'],
        items: [
          {
            title: 'Products',
            href: '/portal/stripe/product',
            roles: ['SuperAdmin'],
          },
          {
            title: 'Subscriptions',
            href: '/portal/stripe/subscription',
            roles: ['SuperAdmin'],
          },
          // {
          //   title: 'Payments',
          //   href: '/portal/stripe/payment',
          //   roles: ['SuperAdmin'],
          // },
        ]
      }
    ]
  },
  {
    roles: ['SuperAdmin'],
    items: [
      {
        title: 'Payments',
        icon: PaymentsIcon,
        href: '/portal/payment',
        roles: ['SuperAdmin'],
      }
    ]
  },
];

function hasAccess(roles: string[], user: User) {
  return roles?.some(role => user?.roles?.find(r => r.name === role));
}

function renderNavItems({
  user,
  items,
  pathname,
  depth = 0
}: {
  user: User;
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => {
          if (item && user && hasAccess(item.roles, user)) {
            return reduceChildRoutes({ user, acc, item, pathname, depth })
          }
          return acc
        },
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  user,
  acc,
  pathname,
  item,
  depth
}: {
  user: User
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          user,
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const isClient = user.roles.find((r: any) => r.name === UserRole.client);
  // const { setIsOpen, isOpen } = useTour();
  const { settings, saveSettings } = useSettings();
  const { put: updateUserSetting } = useUpdateUserSetting(user.userSettingId);

  // useEffect(() => {
  //   if (settings?.showHint && isClient) {
  //     setTimeout(() => {
  //       setIsOpen(true);
  //       saveSettings({ ...settings, showHint: false });
  //       updateUserSetting({ showHint: false })
  //     }, 500);
  //   }
  // }, [settings])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const latestSubscription = getLastSubscription(user?.subscriptionHistory);
  const { remainingDays } = getSubscriptionDetails(latestSubscription);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/portal/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user?.profilePhotoUrl}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
            className="tour-step-1"
          >
            <Link
              component={RouterLink}
              to="/portal/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user?.firstName} {user?.lastName}
            </Link>
            {!!user?.subscriptionHistory?.length && isClient && (
              // <Typography
              //   variant="body2"
              //   color="textSecondary"
              // >
              //   {user?.referral?.code}
              // </Typography>
              <>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  Subscription:
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/portal/account?tab=subscription"
                  >
                    {latestSubscription?.subscriptionType?.title?.replace('Subscription', '')}
                  </Link>
                </Typography>
                { remainingDays < 31 && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                  >
                    {`${remainingDays}  day${remainingDays < 2 ? '' : 's'} remaining`}
                  </Typography>
                )}
              </>
            )}
            {/* { user?.roles[0] && (
              <Typography
                variant="body2"
                color="textSecondary"
              >
                {user.roles[0].name?.replace(/([A-Z])/g, ' $1').trim()}
              </Typography>
            )} */}
            {/* {user?.tier && (
              <Typography
                variant="body2"
                color="textSecondary"
              >
                Your tier:
                {' '}
                <Link
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.tier}
                </Link>
              </Typography>
            )} */}
            {/* {(!isOpen && isClient) && <Button variant='contained' size="small" color='primary' onClick={() => setIsOpen(true)} style={{ marginTop: 10 }}>Tour</Button>} */}
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section, index) => {
            if (hasAccess(section?.roles, user)) {
              if (section?.subheader) {
                return (
                  <List
                    key={index}
                    subheader={(
                      <ListSubheader
                        disableGutters
                        disableSticky
                      >
                        {section.subheader}
                      </ListSubheader>
                    )}
                  >
                    {renderNavItems({
                      user,
                      items: section.items,
                      pathname: location.pathname
                    })}
                  </List>
                )
              } else {
                return renderNavItems({
                  user,
                  items: section.items,
                  pathname: location.pathname
                })
              }
            }
            return null
          })}
        </Box>
        {/* <Divider /> */}
        {/* { isClient && (
          <Box p={2} >
            <RadialChart/>
          </Box>
        )} */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
