import React, { useContext } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import CTA from './CTA';
import Subsciption from './Subsciption';
import Footer from './Footer';
import AppContext from 'src/contexts/AppContext';
import CalculateSavings from './CalculateSavings';
import SuccessStories from './SuccessStories';
import KeyFeatures from './KeyFeatures';
import Faq from './Faq';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView: FC = () => {
  const classes = useStyles();
  const { scrollToPrice: executeScroll, pricingRef, savingsAnalysisRef, faqRef, contactRef, productRef } = useContext(AppContext);

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Fade duration={3000}>
        <Hero/>
      </Fade>
      <CTA/>
      <Fade bottom cascade>
      <CalculateSavings savingsAnalysisRef={savingsAnalysisRef} />
      </Fade>
      <KeyFeatures productRef={productRef}/>
      <Faq faqRef={faqRef}/>
      {/* <SuccessStories/> */}
      <Subsciption subscriptionRef={pricingRef}/>
      <Footer contactRef={contactRef}/>
    </Page>
  );
};

export default HomeView;
