import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  Grid,
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import Zoom from 'react-reveal/Zoom';

interface CTAProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: 50,
    paddingBottom: 50
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

const CTA: FC<CTAProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
      <Zoom>
        <Box display={'flex'} flexDirection={'row'}>
          <Grid container>
            <Grid md={6} style={{ display: 'flex', flex: 1 }}>
              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center'
              }}>
                <Typography
                  variant="h2"
                  align="left"
                  color="textSecondary"
                  style={{ fontWeight: 'bold', color: '#fff' }}
                  >    
                    Your house should not
                </Typography>
                <Typography
                    variant="h2"
                    align="left"
                    color="textSecondary"
                    style={{ fontWeight: 'bold', color: '#fff' }}
                  >
                  keep you up at night.
                </Typography>
              </Box>
            </Grid>
            <Grid md={6}>
              <Typography
                variant="body1"
                align="left"
                color="textSecondary"
                style={{ marginTop: 20 , fontFamily: 'Poppins' , fontWeight: 300, color: '#fff' }}
              >
               Liberte is a powerful application that helps you pay your mortgage and loans off faster. By looking at your current income and expenses, our application will help you pay your mortgage and loans strategically through recommended smart payments. Imagine paying your house off in only 7-10 years instead of 20-30 years and saving 10 plus years of interest! 
              </Typography>
            </Grid>
          </Grid>
          {/* <Box flex={1} display={'flex'}  justifyContent="center" alignContent='center' alignItems={'center'}>
            <Box>
              <Typography
                variant="h2"
                align="left"
                color="textSecondary"
                style={{ fontWeight: 'bold', color: '#fff' }}
                >    
                  Your house should not
              </Typography>
              <Typography
                  variant="h2"
                  align="left"
                  color="textSecondary"
                  style={{ fontWeight: 'bold', color: '#fff' }}
                >
                keep you up at night.
              </Typography>
            </Box>
          </Box>
          <Box flex={1}>
            <Typography
                variant="body1"
                align="left"
                color="textSecondary"
                style={{ marginTop: 20 , fontFamily: 'Poppins' , fontWeight: 300, color: '#fff' }}
              >
               Liberte is a powerful application that helps you pay your mortgage and loans off faster. By looking at your current income and expenses, our application will help you pay your mortgage and loans strategically through recommended smart payments. Imagine paying your house off in only 7-10 years instead of 20-30 years and saving 10 plus years of interest! 
              </Typography>
          </Box> */}
        </Box>
        </Zoom>
      </Container>
    </div>
  );
};

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;
